import { Box, Divider, Grid, InputAdornment, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import Breadcrumbs from "../../components/common2/Breadcrumbs";
import { getAIResponses } from '../../redux/actions/ChatBot/chatBot';
import { TextBoxLatest } from '../common/TextBoxLatest/TextBoxLatest';
import { debounce } from 'lodash';
import SearchIcon from "@mui/icons-material/Search";

const columns: GridColDef[] = [
    {
        "field": "id",
        "headerName": "ID",
        "width": 70
    },
    {
        "field": "title",
        "headerName": "Title",
        "width": 150
    },
    {
        "field": "description",
        "headerName": "Description",
        "width": 450
    },
    {
        "field": "entityResolution",
        "headerName": "Resolution",
        "width": 350
    }
];
export function AIResponses() {
    const dispatch = useDispatch();
    const searchRef:any = React.createRef();
    const [list,setList] = React.useState([])


    const table = React.useMemo(()=>{
        return <DataGrid 
                    rows={list} 
                    columns={columns} 
                    disableRowSelectionOnClick
                />
    },[list])
    React.useEffect(()=>{
        if(searchRef?.current){
            searchRef.current.focus();
        }
        onSearchKeyDownHandler({key:"Enter"})
    },[])
    const getAIResponsesCB = (isSuccess:boolean,response:any) =>{
        setList(isSuccess ? response : []);
    }
    const onSearchKeyDownHandler = debounce((event: any) =>{
        if (event.key === 'Enter') {
            dispatch(getAIResponses(event?.target?.value || "",getAIResponsesCB))
        }
    },300)
    const onchangeHandler = debounce((event: any) =>{
        dispatch(getAIResponses(event?.target?.value || "",getAIResponsesCB))
    },300)
    
    return (
        <>
            <Breadcrumbs
                data={[
                    {
                        title: 'Knowledge Base ',
                        path: '/KnowledgeBase'
                    },
                    {
                        title: 'AI Search ',
                        path: '/AI-Search'
                    }
                ]}
            />
            <Box className="white-bg p-1">
                <Box className="pb-1">
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item xs={12} md={4}>
                            <Typography component="h2">AI Search</Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextBoxLatest
                                placeholder="Please Enter"
                                ref={searchRef}
                                name='search'
                                onKeyDownHandler={onSearchKeyDownHandler}
                                onchangeHandler={onchangeHandler}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment onClick={()=>{onSearchKeyDownHandler({key:"Enter"})}} className='icon-holder pointer' position="end">
                                            <SearchIcon className="pointer"  /> &nbsp; &nbsp;
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <Box className="ai-results-table pt-1" style={{marginTop:"10px", height: "85vh", width: '100%' }}>
                    {table}
                </Box>
            </Box>

            
        </>
    );
}




// const mapStateToProps = (state:IState) => {
//     return ({
//       allTickets: state?.AllTicketsReducer?.allTickets,
//       allResponses : state?.TicketsReducer?.allResponses
//     })
//   };
  
export default AIResponses;
//connect(mapStateToProps)(AIResponses);