import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { FallbackAvatar } from '../MobileUtilities/ContactView';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
const fileTypeObjs:any = {
    "jpg":"image",
    "jpeg":"image",
    "png":"image",
    "webp":"image",
    "svg":"image",
    "img":"image",
    "pdf":"pdf",
    "doc":"wordDoc",
    "docx":"wordDoc",
    "zip" : "zip"
}
const getFileType = (url:string) =>{
    return `${url.split('.').pop()?.toLocaleLowerCase()}`;
}
function FileViewFromBlobUrl({url=""}:{url:string}) {
    const fileExt = getFileType(url);
    return (
        <Box>
           <FileView fileExt={fileExt} url={url}/>
        </Box>
    )
}


export function FileView({fileExt,url,onRemoveHandler,name=""}:{fileExt:string,url:string,onRemoveHandler?:any,name?:string}) {
    const [show,setShow] = useState(false);
    const closeView = ()=>{
        console.log("closeView")
        setShow(false)
    }
    return (
        <>
            <Box className="mblAttachment">
                <Box onClick={()=>setShow(true)}>
                    {fileTypeObjs[fileExt] === "image" &&
                        <FallbackAvatar
                            src={url}
                            alt={"File"}
                            fallback={`File`}
                            width={"100%"}
                            height={50}
                            parentClassName="mobileAttachment"
                        />
                    }
                    {fileTypeObjs[fileExt] === "pdf" &&
                        <PictureAsPdfIcon className='mobileFileIcon' />
                    }
                    {fileTypeObjs[fileExt] === "zip" &&
                        <FolderZipIcon className='mobileFileIcon' />
                    }
                    {!fileTypeObjs[fileExt] &&
                        <InsertDriveFileIcon className='mobileFileIcon' />
                    }
                </Box>
                {show && 
                    <Box className="mblViewFile text-center" onClick={closeView}>
                        <Box className="white-bg p-1 w-100" onClick={(e:any)=>e.stopPropagation()} >
                        <Box className="text-right pb-2">
                            <CloseIcon onClick={closeView}/>
                        </Box>
                        {fileTypeObjs[fileExt] === "image" &&
                                <FallbackAvatar
                                    src={url}
                                    alt={"File"}
                                    fallback={`File`}
                                    width={"100%"}
                                    height={"auto"}
                                    parentClassName="mobileAttachment"
                                />
                            }
                            {fileTypeObjs[fileExt] === "pdf" &&
                                <PictureAsPdfIcon className='mobileFileIcon' />
                            }
                            {fileTypeObjs[fileExt] === "zip" &&
                                <FolderZipIcon className='mobileFileIcon' />
                            }
                            {!fileTypeObjs[fileExt] &&
                                <InsertDriveFileIcon className='mobileFileIcon' />
                            }
                            <Box className="pt-1"><Typography sx={{fontSize: 11}}>{name}</Typography></Box>
                            <Box className="row m-0 pt-2 gap15 justify-content-center">
                                {onRemoveHandler && <Typography onClick={()=>{
                                    onRemoveHandler();
                                    closeView();
                                }} className="txt-danger">Remove Item</Typography>}
                                <a href={url} target='blank'>Open in new Tab</a>
                            </Box>
                        </Box>
                    </Box>
                }
            </Box>
      </>
    )
}

export default FileViewFromBlobUrl
