import axios from "axios";
import configs from "../../../configs/config";
import { triggerAppAlert } from "../SelfServicePortal/ContactAid/ContactAid"; 
import { IMessageItem } from "../../../components/ChatBot/ChatBot";

const botUrl = `${configs?.BASE_URL}/ChatInit/bot`;
const aiSuggestion = `${configs?.BASE_URL}/ai/search?search=`;// "https://simplisysapi.vizionsys.com/documents/tickets?search_text=";
//https://simplisysapi.vizionsys.com/gateway/ai/search
const initChatUrl = `${configs?.BASE_URL}/ChatInit/initiate`;
const prevChatUrl = `${configs?.BASE_URL}/ChatInit/filter`

// SYNC endpoints
const articleSync = `${configs?.BASE_URL}/article/ai/sync`;
const ticketSync = `${configs?.BASE_URL}/ticket/ai/sync`;


const getBotReply = (searchKey:string,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const chatId = localStorage.getItem("ChatInitId")
      if(!chatId) return;
      const response = await axios.post(botUrl,{
            "ChatInitId" : chatId,
            "RequestMessage" : searchKey
        });
        // ,"text_query": searchKey}
    //   const response1 = await axios(aiSuggestion);
      try{
        if(callback) callback(true,`${response?.data?.responseMessage || "Can you provide some extra details?"}`);
      }catch(e:any){
        console.log('e')
      }
    } catch (error:any) {
        if(callback) callback(false,`${error?.response?.data?.error || "Can you provide some extra details?"}`);
        return console.log(error);
    }
  };
}
const convertInteractionToChat = (botInteractionList:any=[]) =>{
  const tempArr:IMessageItem[] = []
  botInteractionList.map((i:any)=>{
    tempArr.unshift({
      type :"response",
      message : i?.responseMessage,
      createdAt : i.createdAt ,
    })
    
    if(i?.requestMessage){
        tempArr.unshift({
            type :"request",
            message : i?.requestMessage,
            createdAt : i.createdAt
        })
    }          
  })
  return tempArr;
}
export const initiateChat = (callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(initChatUrl);
      if(response?.data){
        const tempArr:IMessageItem[] = convertInteractionToChat(response.data.botInteractionList);
        console.log('ChatInitId', tempArr);
        localStorage.setItem("ChatInitId",response?.data?.id)
        try{
          if(callback) callback(tempArr);
        }catch(e:any){
          console.log('e')
        }
      }
      
    } catch (error:any) {
        // if(callback) callback(false,`${error?.response?.data || "Can you provide some extra details?"}`);
        return console.log(error);
    }
  };
}

export const getAIResponses = (searchKey:string,callback?:any) => {
    return async (dispatch: any) => {
      try {
        const response = await axios(`${aiSuggestion}${searchKey}`);
        try{
          if(callback) callback(true,response?.data);
        }catch(e:any){
          console.log('e')
        }
      } catch (error:any) {
          if(callback) callback(false,`${error?.response?.data || "Can you provide some extra details?"}`);
          return console.log(error);
      }
    };
  }



export const lazyLoadConversations = (pageNum: Number,callback:any) => {
    return async (dispatch: any) => {
      try {
        let count = 6;
        let response = await axios.get(`${initChatUrl}?page=${pageNum}&count=${count}`);
        const {botInteractionList = []} = response.data;
        const formattedVal:IMessageItem[] = convertInteractionToChat(botInteractionList);
        if(callback) callback(formattedVal, botInteractionList.length >= count);
      } catch (error:any) {
        console.log(error);
      }
    };
}

// ==================== SYNC OPTIONS ====================


export const syncArticlesAI = (callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(articleSync);
      dispatch(triggerAppAlert("Articles Sync Initiated.!","success"))
    } catch (error:any) {
        dispatch(triggerAppAlert("Articles Sync Failed, Please try again later.","error"))
        return console.log(error);
    }
  };
}
export const syncTicketsAI = (callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(ticketSync);
      dispatch(triggerAppAlert("Tickets Sync Initiated.!","success"))
    } catch (error:any) {
        dispatch(triggerAppAlert("Tickets Sync Failed, Please try again later.","error"))
        return console.log(error);
    }
  };
}










export default getBotReply;