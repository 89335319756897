import axios from "axios";
import configs from "../../../configs/config";
import LanguageData from "../../../configs/LanguageData.json";
import { alertMessage } from "../SystemIcons/systemIcons";
// export const url =  `http://localhost:1000/api/master/Subscription`;
export const getEditionsUrl = `${configs.BASE_URL}/common/subscription`
export const subcriptionUrl =  `${configs.BASE_URL}/master/Subscription`;
export const featureUrl =  `${configs.BASE_URL}/master/PreDefinedFeature`;
export const featurePermissions = `${configs.BASE_URL}/subscription`;
interface IFeatureValue {
  value : string | boolean;
}
export interface IFeature {
  "SubscriptionId"?: number;
  "PreDefinedFeatureId"?: number;
  "displayName": string;
  "Ranking": number;
  "data": string;
  "dataType": "string" | "bool" ;
  "isPublishToCustomer": false;
  "editionValues" ?: IFeatureValue[];
  "dataToPrefill" ?: iValue;
}

interface iValue {
  [key: string]: string;
}
const generateDataToPrefill = (arr=[]) =>{
    const tempValue:any = {};
    arr.map((i:any)=>{
          tempValue[`featureItem_${i.preDefinedFeatureId}_${i.subscriptionId}`] = i.data;
          tempValue[`_feature_${i?.preDefinedFeatureId}`] = i.displayName;
          tempValue[`published_${i?.preDefinedFeatureId}`] = i.isPublishToCustomer ? "true" : "";
    })
   return tempValue;
}
export const getAllFeature = (callback?: any, isSSP: boolean = false) => {
  return async (dispatch: any) => {
    try {
      Promise.all(
        [subcriptionUrl,featureUrl].map((endpoint: string, index: number) => axios.get(`${endpoint}`))
      ).then((all) => {
        let objToPreFill:any = {};
        const newItemsObj:any = {};

        all[0].data = all[0].data.map((i:any)=>{
          const {id,name,displayName,isEnterprises,isActive}= i;
          const tempValue:any = {};
          (i?.featureList || []).map((i:any)=>{
            const featureId = i?.preDefinedFeatureId || newItemsObj?.[i.displayName]?.id ||Math.random()*10000000000000000000;
            tempValue[`featureItem_${featureId}_${i.subscriptionId}`] = i.data;
            tempValue[`_feature_${featureId}`] = i.displayName;
            tempValue[`published_${featureId}`] = i.isPublishToCustomer ? "true" : "";
            tempValue[`_featureRank_${featureId}`] = i.ranking || "";

            /* ======== PICKING NEW FEATURES ======== */
            if(i.preDefinedFeatureId === null && !newItemsObj[`${i.displayName}`]){
                newItemsObj[`${i.displayName}`] = {
                  "id": featureId,
                  "name": "",
                  "dataType": "bool",
                  "isPublishToCustomer": i.isPublishToCustomer ? "true" : "",
                  "editionList" : [],
                  "isNew":true
                }
            }
          })
          objToPreFill = {...objToPreFill, ...tempValue}
          return {id,name,displayName,isEnterprises,isActive};
        })

        console.log("newFeatureList_newFeaturesObj",newItemsObj)

        all[1].data = ([...all[1].data||[],...Object.values(newItemsObj)]).map((i:any)=>{
          const {id,name,dataType,isPublishToCustomer}= i;
          return {
            id, name, dataType, isPublishToCustomer, 
            "isNew" : i.isNew ? true : false,
            editionList : all[0].data
          }
        })

        console.log("STORE_ALL_FEATURE", all[1].data,objToPreFill)




        dispatch({ type: "STORE_ALL_FEATURE", payload: { subscriptions: all[0].data, featureList: all[1].data}});
        
        try {if(callback) callback(all[1].data,all[0].data,objToPreFill)} catch (e) { }
      });
    } catch (error: any) {
      return console.log(error.response);
    }
  };
};

export const updateFeature = (payload:any,callback:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.put(`${subcriptionUrl}/${payload?.id}`,payload);
      if(callback) callback(response.data);
      // dispatch(editPricingValue(response.data))
      dispatch(alertMessage(`Pricing Plan ${response?.data?.name} Successfully!`,"success"))
    } catch (error:any) {
      if (error.response) {
        console.log('error=>', error.response.data);
        dispatch(alertMessage("Pricing updation failed! Please try again.","error"))
      }  
    }
  };
};
export const updateSubscriptions = (data: any, callback: any = undefined) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.put(subcriptionUrl, data);
      dispatch(alertMessage(`${LanguageData.SUCCESS_NEW_FEATURE}${LanguageData.TICKET_CREATED}`,"success"))
      try { callback && callback("0", response.data) } catch (e) { }
    } catch (error: any) {
      dispatch(alertMessage(error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!" : "Something went wrong! Please try again.", "error"))
      callback && callback("1", error.response)
      return console.log(error.response);
    }
  };
};
export const getAllPreDefinedFeature = (callback?: any, isSSP: boolean = false) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${featureUrl}`);
      response.data = response.data.map((i: any) => {
        return { ...i, rid: `#${i.id}`, value: i.name, label: i.name }
      })
      dispatch({
        type: "STORE_ALL_PRE_DEFINED_FEATURE",
        payload: response.data
      })
      try { callback && callback("0", response.data) } catch (e) { }
    } catch (error: any) {
      callback && callback("1", error.response)
      return console.log(error.response);
    }
  };
};

export const getEditions = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${getEditionsUrl}`);
      response.data = response.data.map((i:any)=>{
              return {
                  ...i,
                  pricePerAgent : i.featureList.find((j:any)=>j?.preDefinedFeature?.name ==="PricePerAgent")?.data || "-"
              }
      })
      // response.data = response.data.map((i: any) => {
      //   return { ...i, rid: `#${i.id}`, value: i.name, label: i.name }
      // })
      dispatch({
        type: "STORE_VIEW_ONLY_FEATURES",
        payload: response.data
      })
    } catch (error: any) {
      return console.log(error.response);
    }
  };
};


export const getUserEditionPermissions = (callback?: any, isSSP: boolean = false) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${featurePermissions}`);
      // response.data = response.data
      const tempObj :any= {
        currentPlan:{
          id: response.data.id,
          name: response.data.name
        }
      };
      (response?.data?.featureList || []).map((i:any)=>{
        const {isPublishToCustomer,preDefinedFeature} = i
        if(isPublishToCustomer && preDefinedFeature){
          if(preDefinedFeature.dataType === "bool" && i.data === "false" ) return;
           tempObj[preDefinedFeature.name.replaceAll(" ","_")] = true
        }
      })
      dispatch({
        type: "STORE_FEATURE_PERMISSIONS",
        payload: tempObj
      })
    } catch (error: any) {
      return console.log(error.response);
    }
  };
};