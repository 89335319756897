import axios from "axios";
import { ActionType } from "../../redux/actions/dashboard";
import configs from "../../configs/config";
export const url = `${configs.BASE_URL}/user/contactus`;

export const updateContactUs = async (id: any, data: any, callback?: any) => {
  try {
    const response = await axios.put(`${url}/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    callback(true);
  } catch (error: any) {
    if (error.response) {
      callback(false);
    }
  }
};

export const submitContactUs = async (data: any, callback?: any) => {
  try {
    const response = await axios.post(`${url}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    callback(true);
  } catch (error: any) {
    if (error.response) {
      callback(false);
    }
  }
};

export const getContactById = async () => {
    try {
        let result = await axios.get(`${url}`);
        return result.data;
    } catch (e) {
        console.log(e)
        return false
    }
  }

export default submitContactUs;
