import React, { Suspense, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Routes } from "react-router-dom";
import MobileLoader from "../components/MobileComponents/MobileCommon/MobileLoader";
import "../components/MobileComponents/mobileStyle.css";
import { IState } from "../redux/reducers/rootReducers";
import { AdminPortalAuth, MobileLoggedInAuth, MobileLoginLoader, PrivateAuth, SSPAuth } from "./PrivateAuth";
const MobileAppRouter: React.FC = () => {
  const [tokenValues,setTokenValues] = useState<any>({});

  const isUserLoggedIn = useSelector((state: IState) => state?.LoginReducer?.isLoggedIn);
  const AllData = useSelector((state: IState) => state?.TicketsReducer?.allResponses?.isLoaded);
  // const isRolesLoaded = useSelector((state: IState) => state?.LoginReducer?.roleAccess?.isLoaded);
  
  useEffect(()=>{
    let logInfo: any = localStorage.getItem("loginInfo");
    setTokenValues(JSON.parse(logInfo || "{}"));
  },[isUserLoggedIn])
  
  const returnRoutes = useMemo(() =>{
    const { isContactLogin,isAdminLogin,isAgentLogin } = tokenValues;
    console.log("returnRoutestokenValues",tokenValues,AllData)
    if(isContactLogin){
      return SSPAuth();
    }else if(isAdminLogin){
      return AdminPortalAuth();
    }else if(isAgentLogin){
      if(AllData){
        return MobileLoggedInAuth();
      }else {
        return MobileLoginLoader();
      }
    }else{
      return PrivateAuth();
    }
  },[tokenValues,isUserLoggedIn,AllData])
  return (
    <>
    <Suspense fallback={<><MobileLoader /></>}>
        <Routes>
            {/* {PostLoginAdminAuth()} */}
            {/* {(!isUserLoggedIn) && PrivateAuth()}
            {(isUserLoggedIn && AllData && isRolesLoaded) &&  MobileLoggedInAuth()}
            {(isUserLoggedIn && isContactLoggedIn) &&  SSPAuth()} */}
            {returnRoutes}
        </Routes>
    </Suspense>
    </>
  );
};

export default MobileAppRouter;
