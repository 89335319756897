import React, { useState, useMemo, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link, useLocation } from "react-router-dom";
import CommonStyles from "../../common/CommonStyles";
import logoImg from "./../../../../src/assest/icons/logo.png";
import Home from "./../../../../src/assest/icons/menu/home.svg";
import Sys from "./../../../../src/assest/icons/menu/sys.svg";
import UserIcon from "./../../../../src/assest/icons/menu/user.svg";
import ReportIcon from "./../../../../src/assest/icons/report.svg";
import { IState } from "../../../redux/reducers/rootReducers";
import { useDispatch, useSelector } from "react-redux";
import CalSvg from "./../../../../src/assest/icons/menu/calendar.svg";
import DBIcon from "./../../../../src/assest/db.svg";
// import AIIcon from "./../../../../src/assest/AI.png";
import TcktSvg from "./../../../../src/assest/tckt.svg";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { theme } from "../../../redux/actions/Theme/Theme";
import { DoLogOutAction, StoreAccessData } from "../../../redux/actions/Login/Login";
import { useNavigate } from "react-router-dom";
import ConfirmationNumberOutlined from '@mui/icons-material/ConfirmationNumberOutlined';
import getSideBarPermission from "./SidebarPermissions";
import getSystemType from "../../../redux/actions/SystemTypes/SystemTypes";
import { getPermission } from "../../../redux/actions/SystemManagement/Permission";
import getTicket from "../../../redux/actions/SystemManagement/ticket";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { storeAllResponses } from "../../../redux/actions/TicketsManagement/TicketsManagement";
import { useMsal } from "@azure/msal-react";
import { Tooltip } from "@mui/material";
const drawerWidth = 200;

const SideNavbar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationVal = useLocation();
  const currAgent = useSelector(
    (state: IState) => state?.agentReducer?.loggedInAgent
  );
  const systemType = useSelector(
    (state: IState) => state.systemTypeReducer?.Data
  );
  const currRole = useSelector(
    (state: IState) => state?.roleReducer?.LOGGED_URS_ROLE
  );
  const PermissionData = useSelector(
    (state: IState) => state?.permissionReducer?.Data?.data
  );
  const useStyles = CommonStyles;
  const classes = useStyles();
  const [curUserRole, setcurUserRole] = useState(0);
  const [firstLoad, setfirstLoad] = useState(true);
  const [roleAccess, setroleAccess] = useState({} as any);
  const closeMenu = () => {
    const bodyTag = document.getElementsByTagName("body");
    bodyTag[0].classList.remove("menuOpened");
  };
  function handleDrawerClose() { }
  function isActive(pathName: string) {
    return locationVal?.pathname === pathName;
  }
  useEffect(() => {
    if (Object.keys(roleAccess).length > 0) return;
    if (currRole?.agentPermissionsList &&
      PermissionData && PermissionData.length > 0) {
      let RoleAccess: any = getSideBarPermission(currRole.agentPermissionsList, PermissionData, currRole.isSuperAdmin)
      dispatch(StoreAccessData(RoleAccess))
      setroleAccess(RoleAccess)
      console.log('RoleAccess-Agent', RoleAccess)
    }
  }, [currRole,PermissionData]);
  useEffect(() => {
    if (currAgent.id) {
      dispatch(getPermission());
    }
  }, [currAgent]);
  const { instance } = useMsal();
  function LogOut() {
    if(instance.logout) instance.logout();
    dispatch(theme(false));
    localStorage.clear();
    dispatch(DoLogOutAction());
    dispatch(storeAllResponses({}));
    navigate("/");
  }


  return (
    <Drawer
      variant="permanent"
      className={`${classes.mainSideHolder} sidebarHolder`}
    >
      <div className={`${classes.mainSideBar} home-sidebar`}>
        <div>
          {/* <IconButton onClick={handleDrawerClose}>
          <MenuIcon />
        </IconButton> */}
          <Link to="/" className="sidebarLogo">
            <img src={logoImg} alt="" />
            <span></span>
          </Link>
        </div>
        <Divider />
        <List>
          <Link to="/home" onClick={()=>closeMenu()}>
            <ListItem className={[isActive("/") ? "active" : ""].join(" ")}>
              <ListItemIcon id="homeIcon">
                <img src={Home} alt="" />
                <span className="sidebar-lists">Home</span>
              </ListItemIcon>
            </ListItem>
          </Link>

          {/* <Link to="/AIManagement" onClick={()=>closeMenu()}>
            <ListItem className={`sidebar-AI-icon ${isActive("/AIManagement") && "active" }`}>
              <Tooltip title="Explore with AI">
                <ListItemIcon id="homeIcon">
                  <img src={AIIcon} alt="" />
                  <span className="sidebar-lists">AI</span>
                </ListItemIcon>
              </Tooltip>
            </ListItem>
          </Link> */}

          {(roleAccess.isSuperAdmin || roleAccess?.TicketDashboardAccess) && 
          <>
          <Link to="/home/ticket/new-ticket" onClick={()=>closeMenu()}>
            <ListItem
              className={[isActive("#/CMDB") ? "active" : ""].join(" ")}
            >
              <ListItemIcon id="newTicIcon">
                <ConfirmationNumberOutlined />
                <span className="sidebar-lists">
                  New <br /> Ticket
                </span>
              </ListItemIcon>
            </ListItem>
          </Link>
          <Link to={`/TicketManagementSys`} onClick={()=>closeMenu()}>
            <ListItem
              className={[isActive(`/TicketManagementSys`) ? "active" : ""].join(
                " "
              )}
            >
              <ListItemIcon id="ticMngIcon">
                <img src={TcktSvg} alt="" />
                <span  className="sidebar-lists">
                  Tickets <br /> Management
                </span>
              </ListItemIcon>
            </ListItem>
          </Link>
          </>
          }
          <Link to="/calender" onClick={()=>closeMenu()}>
            <ListItem
              className={[isActive("/calender") ? "active" : ""].join(" ")}
            >
              <ListItemIcon id="calenderIcon">
                <img src={CalSvg} alt="" />
                <span className="sidebar-lists">Calender</span>
              </ListItemIcon>
            </ListItem>
          </Link>
          {(roleAccess.isSuperAdmin || roleAccess?.assetAdminAccess) && 
            <Link to={roleAccess.isSuperAdmin || roleAccess.CMDB ? "/CMDB" : '/AssetManagement'} onClick={()=>closeMenu()}>
              <ListItem
                className={[isActive("#/CMDB") ? "active" : ""].join(" ")}
              >
                <ListItemIcon id="CMDBIcon">
                  <img src={DBIcon} alt="" />
                  <span className="sidebar-lists">CMDB</span>
                </ListItemIcon>
              </ListItem>
            </Link>
          }
          {(roleAccess.isSuperAdmin || roleAccess?.contactAdminAccess) && 
            <Link to={roleAccess.isSuperAdmin || roleAccess.Contacts ? "/contact" : '/ContactManagement'} onClick={()=>closeMenu()}>
              <ListItem
                className={[isActive("/contact") ? "active" : ""].join(" ")}
              >
                <ListItemIcon id="contIcon">
                  <PeopleAltOutlinedIcon />
                  <span className="sidebar-lists">Contacts</span>
                </ListItemIcon>
              </ListItem>
            </Link>
          }
          {/* className="disabled" */}
          {(roleAccess.isSuperAdmin || roleAccess?.knowledgeBaseAccess) &&   
            <Link to={'/ArticlesList'} onClick={()=>closeMenu()}>
              <ListItem
                className={[isActive("#/ArticlesList") ? "active" : ""].join(" ")}
              >
                <ListItemIcon id="knldgIcon">
                  <SchoolOutlinedIcon />
                  <span className="sidebar-lists">Knowledge <br />Base </span>
                </ListItemIcon>
              </ListItem>
            </Link>
          }
          
          {/* <a> 
            <ListItem
              className={[isActive("#/CMDB") ? "active" : ""].join(" ")}
            >
              <ListItemIcon>
                <SummarizeOutlinedIcon />
                <span>Reports</span>
              </ListItemIcon>
            </ListItem>
          </a> */}
          {(roleAccess.isSuperAdmin ||roleAccess?.ReportsManagementAccess) &&
            <Link to={'/ReportsManagement'} onClick={()=>closeMenu()}>
              <ListItem
                className={[isActive("/ReportsManagement") ? "active" : ""].join(" ")}
              >
                <ListItemIcon id="ReportsIcon">
                  <SummarizeOutlinedIcon />
                  <span className="sidebar-lists">Reports</span>
                </ListItemIcon>
              </ListItem>
            </Link>
          }
          {(roleAccess.isSuperAdmin ||roleAccess?.sysAdminAccess || roleAccess?.ApisAdminAccess || roleAccess?.interfacesAdminAccess) &&
            <Link to="/adminPanel" onClick={()=>closeMenu()}>
              <ListItem
                className={[isActive("/adminPanel") ? "active" : ""].join(
                  " "
                )}
              >
                <ListItemIcon id="sysMngIcon">
                  <img src={Sys} alt="" />
                  <span className="sidebar-lists">
                    System <br /> Management
                  </span>
                </ListItemIcon>
              </ListItem>
            </Link>
           }
           {(roleAccess.isSuperAdmin || roleAccess?.usrAdminAccess) &&
            <Link to="/UserManagement" onClick={()=>closeMenu()}>
              <ListItem
                className={[isActive("/UserManagement") ? "active" : ""].join(" ")}
              >
                <ListItemIcon id="userMangIcon">
                  <img src={UserIcon} alt="" />
                  <span className="sidebar-lists">
                    User <br /> Management
                  </span>
                </ListItemIcon>
              </ListItem>
            </Link>
          }
        </List>
        <Divider />
        <Box className={`md-none ${classes.sideLogoutBtn}`} onClick={LogOut}>
          <LogoutOutlinedIcon />
          <span className="sidebar-lists">logout</span>
        </Box>
      </div>
      <span onClick={closeMenu} className={classes.menuClose}></span>
    </Drawer>
  );
};

export default SideNavbar;
