import { Box, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';
import BottomDrawer from '../MobileCommon/BottomDrawer';
import FormWrapper from './FormWrapper';
import AccordionCollapse from '../MobileCommon/Collapse';
import AddNewButton from '../MobileCommon/AddNewButton';
import { createRef, useEffect, useMemo, useState } from 'react';
import { getAssetsAction } from '../../../redux/actions/AssetsManagement/Assets';
import { TextBoxLatest } from '../../common/TextBoxLatest/TextBoxLatest';
import { AssetItem } from '../MobileCommon/AssetCollapse';
import { debounce } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
interface IChooseAssetProps {
    dispatch?:any;
    assets:any
}
function ChooseAsset(props:IChooseAssetProps) {
    const {dispatch,assets=[]} = props;
    const ref:any = createRef();
    const assetRef:any = createRef();
    const [show,setShow] = useState(false);
    const [loading,setLoading] = useState(true);
    const [searchVal,setSearchVal] = useState("");
    const [records,setRecords] = useState([]);
    const [assetsObj,setAssetsObj] = useState<any>({});
    
    const [values,setValues] = useState({});
    
    const submitChooseAsset = (val:any) =>{
        console.log("submitChooseAsset",val,JSON.stringify(val));
        delete val.searchAsset;
        setValues(val);
        if(assetRef?.current) assetRef.current.value = JSON.stringify(val);
        closeChooseAsset();
    }
    const removeAsset = (name:string) =>{
        const tempObj:any = {...values}
        delete tempObj[name];
        setValues(tempObj);
    }
    const closeChooseAsset = () =>{
        setShow(false)
    }
    const openChooseAsset = () =>{
        setShow(true)
    }
    useEffect(()=>{
        dispatch(getAssetsAction(null,getAssetsActionCB));
    },[])
    useEffect(()=>{
        if(!assets.filter) return;
        const tempObj:any = {};
        const filteredArr =  assets.filter((i:any)=>{
            tempObj[`asset-${i.id}`] = i;
            return i.isActive && i?.searchKey.includes(searchVal.toLowerCase())
        })
        setRecords(filteredArr)
        console.log("tempObj",tempObj)
        setAssetsObj(tempObj)
    },[assets.length,searchVal])

    const getAssetsActionCB = () =>{
        setLoading(false)
    }
    const searchHandler = debounce((e:any)=>{
        setSearchVal(e?.target.value || "")
    },150)



    const assetListItems = useMemo(()=>{
        return <>
            {records.map((i:any)=>{
                return (
                    <Box key={i.id}>
                            {/* <Typography>{i?.searchKey}</Typography> */}
                            <AssetItem 
                                info={i}
                                defaultVal={values}
                            />
                    </Box>
                )
            })}
        </>
    },[records,values])
    return (
        <Box>

            <TextBoxLatest
                ref={assetRef}
                name='assetsList'
                parentClassNames='d-none'
            />
            <AccordionCollapse 
                title={`Assets`}
                body={<>
                    {Object.keys(values).map((i:string)=>{
                        return( 
                            <Box className="mblAssetItem">
                                <Box className="name">
                                    <Typography>#{assetsObj?.[i]?.id} {assetsObj?.[i]?.name}</Typography>
                                    <Typography className="tag">Tag : {assetsObj?.[i]?.assetTag || "-"}</Typography>
                                </Box>
                                <Box onClick={()=>removeAsset(i)} className="icon">
                                    <CloseIcon />
                                </Box>
                            </Box>
                        )
                    })}
                    <AddNewButton onClick={openChooseAsset} label="Choose Asset" />
                </>}
                id="t-asset"
            />
            <BottomDrawer
                isOpened ={show}
                onClose ={closeChooseAsset}
                title="Choose Asset"
            >
                <>
                    <FormWrapper
                        onClose={closeChooseAsset}
                        onSubmit={submitChooseAsset}
                    >
                        <>
                        <TextBoxLatest
                            placeholder="Search Asset ..."
                            title={"Search Asset"}
                            ref={ref}
                            isMandotary={false}
                            errorMsg={``}
                            isError={false}
                            name='searchAsset'
                            type="search"
                            onchangeHandler={searchHandler}
                        />
                        <hr/>
                        {/* {searchVal} */}
                        {loading && <>Loading...</>}
                            
                        {assetListItems}
                        <br/><br/><br/><br/><br/><br/><br/><br/>
                        </>
                    </FormWrapper>
                </>
            </BottomDrawer>
        </Box>
    )
}














const mapStateToProps = (state:IState) => {
    return ({
        assets: state?.AssetsReducer?.AllAssets
    })
  };
  
export default connect(mapStateToProps)(ChooseAsset);
// export default ChooseAsset