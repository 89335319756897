import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { IState } from '../../../redux/reducers/rootReducers';
import { Box, Container, Paper, Typography } from '@mui/material';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import logoImg from "../../../assest/logo/logo.svg";
import { useNavigate } from 'react-router-dom';
import { getEditions } from '../../../redux/actions/userManagement/feature';
import ViewPricingTable from './ViewPricingTable';
import PricingPageCards from './PricingPageCards';
import PricingBanner from '../../../components/SystemManagement/FreeTrail/PricingBanner';

function ViewPricing(props:any) {
  const {dispatch} = props;
  const navigate = useNavigate();
  useEffect(()=>{
    dispatch(getEditions())
  },[])
  return (
    <>
    <PricingBanner />
    <Box className="white-bg p-2 pricingPage">
        <Container>
            {/* <Box>
                <img
                    src={logoImg}
                    alt=""
                    height={66}
                    width={"auto"}
                    onClick={() => {
                      navigate("/");
                    }}
                />
            </Box> */}


            <Box className="pageTitle text-center">
              <h1>Affordable pricing for growing businesses</h1>
              <Box className="row m-0 justify-content-center">
                <Typography><TaskAltOutlinedIcon /> Free 15-day trial</Typography>
                <Typography><TaskAltOutlinedIcon /> No card required</Typography>
                <Typography><TaskAltOutlinedIcon /> 30-day money-back guarantee</Typography>
              </Box>
            </Box>
            
            

            <PricingPageCards />
            

            <Box>
              <ViewPricingTable />
            </Box>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </Container>
    </Box>
    </>
  )
}

const mapStateToProps = (state:IState) => {
    return ({
      featurePermissions: state?.FeatureReducer?.featurePermissions,
    })
  };
  
export default connect(mapStateToProps)(ViewPricing);
// export default ViewPricing
