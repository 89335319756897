import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import NoDataFound from '../../common2/NoDataFound';
import { IState } from '../../../redux/reducers/rootReducers';
import { connect, useDispatch } from 'react-redux';
import CommonStyles from '../../common/CommonStyles';
import { createStyles, makeStyles } from '@mui/styles';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { updateTicketTask } from '../../../redux/actions/TicketsManagement/TicketsManagement';
import { triggerAppAlert } from '../../../redux/actions/SelfServicePortal/ContactAid/ContactAid';
import { useLocation } from 'react-router-dom';
import { FallbackAvatar } from '../../MobileComponents/MobileUtilities/ContactView';
import { ConvertZ } from '../../helpers/helperFunctions';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
function TaskList(props:any) {
    const {taskList=[],AllInfo={},currAgent={},callback,hideTitle}=props;
    const location = useLocation();
    const patamTaskId = new URLSearchParams(location.search).get('taskId');
    const classes = CommonStyles();
    const classes1 = useStyles();
    const dispatch = useDispatch();
    console.log("AllInfoAllInfoAllInfo",AllInfo)
    const taskApprove = (taskObj:any) =>{
        if(!AllInfo?.statusTypes?.Approve) {
            triggerAppAlert(`User doesn't have Role Permission to approve! Please Contact Admin.`, 'warning')
            return;
        }
        const payload = {...taskObj}
        if(payload.ticketTaskLogs){
          payload.ticketTaskLogs.statusId = AllInfo?.statusTypes?.Approve;
          payload.ticketTaskLogs.ticketStatus = AllInfo.statusTypesById[AllInfo?.statusTypes?.Approve];
        }
        dispatch(updateTicketTask(payload,taskApproveCB))//taskObj
    }
    const taskComplete = (taskObj:any) =>{
        if(!AllInfo?.statusTypes?.Complete) {
            dispatch(triggerAppAlert(`User doesn't have Role Permission to Complete! Please Contact Admin.`, 'warning'))
            return;
        }
        const payload = {...taskObj}
        if(payload.ticketTaskLogs){
          payload.ticketTaskLogs.statusId = AllInfo?.statusTypes?.Complete;
          payload.ticketTaskLogs.ticketStatus = AllInfo.statusTypesById[AllInfo?.statusTypes?.Complete];
        }
        dispatch(updateTicketTask(payload,taskApproveCB))//taskObj
    }
    const rejectTask = (taskObj:any) =>{
        if(!AllInfo?.statusTypes?.Reject) {
            triggerAppAlert(`User doesn't have Role Permission to Reject! Please Contact Admin.`, 'warning')
            return;
        }
        const payload = {...taskObj}
        if(payload.ticketTaskLogs){
          payload.ticketTaskLogs.statusId = AllInfo?.statusTypes?.Reject;
          payload.ticketTaskLogs.ticketStatus = AllInfo.statusTypesById[AllInfo?.statusTypes?.Reject];
        }
        dispatch(updateTicketTask(payload,taskApproveCB))//taskObj
    }
    const taskApproveCB = (resStatus:string,response:any) =>{
        console.log('taskApproveCB',resStatus,response)
        triggerAppAlert(`Task ${response?.id} updated successfully!`, 'warning')
        callback(response)
    }
    const getTaskAccess = (item:any)=>{
        let valid = false;
        const {statusId,agentId,managerId} = item?.ticketTaskLogs;
        const {Approve,Reject,Complete}= AllInfo?.statusTypes
        if(currAgent?.id === agentId && managerId === 0){
            if(Approve !== statusId && Reject !== statusId && Complete !== statusId){
                valid = true
            }
        }
        return valid;
    }
    const getAssigneeName = (manager:any,agent:any) =>{
        if(manager?.id){
            return manager?.knownAs;
        }else if(agent?.id){
            return agent?.firstName ? `${agent?.firstName} ${agent?.lastName}` : "Unassigned";
        }else {
            return "";
        }
    }
    return (
        <Box>
            {/* {taskList?.length === 0 && <NoDataFound />} */}
            {taskList?.map((i: any, key: number) => {
                const {title,managerId,manager,agentId,statusId,agent,ticketStatus,dueDateTime} = i?.ticketTaskLogs;
                const isChosen = `${patamTaskId}` === `${i?.id}`;
                return (
                    <Box key={key} className={`task-item ${isChosen ? "active" : ""}  ${classes1.taskItems}`}>
                                                
                        <Typography
                            variant="body2"
                            className={`${classes.themeColorTxt} AssetsTcktsList id`}
                            key={key}
                        >
                            #T{i.id}&nbsp; &nbsp;
                        </Typography>
                        <Typography
                            variant="body2"
                            className={`${classes.themeColorTxt} AssetsTcktsList`}
                            key={key}
                        >
                            <span className={` ${classes1.smlTxt} `}>{!hideTitle && <>{title ? title : "-"}</>}</span>
                        </Typography>



                        <Typography
                            variant="body2"
                            className={`${classes.themeColorTxt} AssetsTcktsList sm-d-none`}
                            key={key}
                        >
                            {managerId > 0 &&
                                <span className={` ${classes1.smlTxt} `} >
                                    Assignee : {`${manager?.userName || "Unassigned"}`}
                                </span>
                            }
                            {agentId > 0 &&
                                <span className={` ${classes1.smlTxt} `} >
                                    Assignee : {agent?.firstName ? `${agent?.firstName} ${agent?.lastName}` : "Unassigned"}
                                </span>
                            }
                        </Typography>
                        <Box className="task-footer">
                            <Typography className={` ${classes1.status} `}>
                                <span >{ticketStatus?.displayName || "Unassigned"}</span>
                            </Typography>
                            <Box className="md-none">
                                <Box className="sla-holder">
                                    <Box>
                                        <FallbackAvatar
                                            src={``}
                                            alt={getAssigneeName(manager,agent)}
                                            fallback={`${getAssigneeName(manager,agent)}`}
                                            width={25}
                                            height={25}
                                        />
                                    </Box>
                                    <Typography >Due Date <b>{`${ConvertZ(dueDateTime)}`}</b></Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                        
                        {(getTaskAccess(i)) && <>
                        {i?.ticketTaskLogs?.workflowNodeId && i?.ticketTaskLogs?.workflowNodeId > 0 ? 
                            <Typography className="task-actions">
                                    <>
                                        <Tooltip title="Approve">
                                            <span style={{ cursor: "pointer", color: "red" }} onClick={()=>{rejectTask(i)}}><CloseOutlinedIcon /> Reject</span>
                                        </Tooltip>
                                        <Tooltip title="Approve">
                                            <span style={{ cursor: "pointer", color: "green" }} onClick={() => { taskApprove(i) }}> &nbsp;<DoneOutlinedIcon /> Approve</span>
                                        </Tooltip>
                                    </>
                            </Typography> 
                            : 
                            <Typography className="task-actions">
                                    <>
                                        <Tooltip title="Complete">
                                            <span style={{ cursor: "pointer", color: "green" }} onClick={() => { taskComplete(i) }}> &nbsp;
                                                <DoneOutlinedIcon /> Complete
                                            </span>
                                        </Tooltip>
                                    </>
                            </Typography> 
                        }</>}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    )
}

const mapStateToProps = (state:IState) => {
    return ({
        currAgent: state?.agentReducer?.loggedInAgent,
        AllInfo: state?.TicketsReducer?.alldataStructure
    })
};

const useStyles = makeStyles(() =>
    createStyles({
        taskItems: {
            // display: "flex",
            width: "100%",
            // justifyContent: "space-between",
            // alignItems: "center",
            border: "1px solid #ddd",
            padding: "10px",
            marginTop:"10px"
        },
        status :{
            fontSize: "10px",
            padding:" 2px 10px",
            fontWeight: "600",
            color: "#17a2b8",
            //marginLeft:"500px"
        },
        smlTxt:{
            fontSize:"12px"
        },
        textWithSpanTitle: {
            lineHeight: '1.5',
            marginBottom: 10,
            fontSize: '13px',
            "& > span": {
                display: 'block',
                fontSize: '11px',
                lineHeight: '2',
                color: "#5559",
            },
            "& > b.tag": {
                background: "#f1f1f1",
                display: "inline-block",
                fontSize: "11px",
                lineHeight: "1",
                padding: "5px 10px",
                marginRight: "5px",
                fontWeight: "400",
                borderRadius: "10px",
            }
        }

    }))

export default connect(mapStateToProps)(TaskList);
