import { Box, Button, Popover, Typography } from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getAIResponses } from "../../../redux/actions/ChatBot/chatBot";
import { getTicketByID } from "../../../redux/actions/TicketsManagement/TicketsManagement";
import parse from 'html-react-parser';
import { IState } from "../../../redux/reducers/rootReducers";

interface IAISuggestionsProps {
  value: string;
  callback: (val: any) => void;
  AiPermission?:boolean;
}
interface iCallBackPayload {
  agent: Object;
  ticketType: Object;
  group: Object;
  priority: Object;
}

function AISuggestions(props: IAISuggestionsProps) {
  const dispatch = useDispatch();
  const { value,AiPermission=false } = props;
  const [suggestions, setSuggestions] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [getByIdVal, setGetByIdVal] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [itemSelected, setItemSelected] = useState<any>(null);

  useEffect(() => {
    console.log("AISuggestions",props)
    if(!AiPermission) return;
    if (value.length > 10) {
      loadTicketSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [value]);

  const loadTicketSuggestions = debounce(() => {
    dispatch(getAIResponses(value, getAIResponsesCB));
  }, 500);

  const getAIResponsesCB = (isSuccess: boolean, response: any) => {
    setSuggestions(isSuccess ? response : []);
    setShowAll(false);
  };
  const handlePopoverClick = (event: any, id: number | string, item: any) => {
    setAnchorEl(event.currentTarget);
    if (item.entityType !== "Article") {
      dispatch(getTicketByID(id, getTicketByIDCB));
    }
    setItemSelected(item)
  };

  const getTicketByIDCB = (resStatus: string, res: any) => {
    if (resStatus === "0") {
      console.log("getTicketByID>>>", res);
      setGetByIdVal(res);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setGetByIdVal(null);
  };

  // Pass all the values you got from the suggestion to the callback so that we can use
  // them to populate the form in Form.tsx
  const onHandleUseClick = () => {
    let payload: iCallBackPayload = {
      agent: getByIdVal?.ticketLogs?.agent,
      ticketType: getByIdVal?.ticketLogs?.ticketType,
      group: getByIdVal?.ticketLogs?.group,
      priority: getByIdVal?.ticketLogs?.priority,
    };
    props.callback(payload);
  };

  const open = Boolean(anchorEl);
  const id = open ? "subject-Selection-Popover" : undefined;
  return (
    <>
      <Box className="subject_suggestion">
        {suggestions.map((i: any, index: number) => {
          return (
            <>
              {(index < 3 || showAll) && (
                <Typography
                  onClick={(event) => {
                    handlePopoverClick(event, i.entityId, i);
                  }}
                >
                  {i?.title}
                </Typography>
              )}
            </>
          );
        })}
        {suggestions.length > 3 && !showAll && (
          <Typography
            onClick={() => {
              setShowAll(true);
            }}
          >
            + {suggestions.length - 3}
          </Typography>
        )}
      </Box>
      {open && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box
            sx={{
              p: 2,
              maxWidth: itemSelected?.entityType === "Article" ? 550 : 300,
            }}
          >

            {(itemSelected?.entityType === "Article") ?
              <Box>
                <Typography component="h3">
                  {itemSelected?.title}
                </Typography>
                <hr />
                {parse(`${itemSelected?.entityResolution || ""}`)}
              </Box>
              : <Box>
                <Typography component="h3">
                  {itemSelected?.title}
                </Typography>
                <hr />
                <Typography>
                  <b>Resolution : </b> {itemSelected?.entityResolution}
                </Typography>
                <Typography
                  className="title-txts"
                  sx={{ fontWeight: 500, marginBottom: 1 }}
                >
                  <span style={{ color: "#555" }}>Agent:</span>{" "}
                  {getByIdVal?.ticketLogs?.agent?.firstName
                    ? `${getByIdVal?.ticketLogs?.agent?.firstName} ${getByIdVal?.ticketLogs?.agent?.lastName}`
                    : "-----"}
                </Typography>
                <Typography
                  className="title-txts"
                  sx={{ fontWeight: 500, marginBottom: 1 }}
                >
                  <span style={{ color: "#555" }}>Group:</span>{" "}
                  {getByIdVal?.ticketLogs?.group?.name || "-----"}
                </Typography>
                <Typography
                  className="title-txts"
                  sx={{ fontWeight: 500, marginBottom: 1 }}
                >
                  <span style={{ color: "#555" }}>Priority:</span>{" "}
                  {getByIdVal?.ticketLogs?.priority?.name || "-----"}
                </Typography>
                <Typography
                  className="title-txts"
                  sx={{ fontWeight: 500, marginBottom: 2 }}
                >
                  <span style={{ color: "#555" }}>Ticket Type:</span>{" "}
                  {getByIdVal?.ticketLogs?.ticketType?.name || "-----"}
                </Typography>
                <Button
                  onClick={onHandleUseClick}
                  aria-describedby={id}
                  variant="contained"
                  sx={{
                    backgroundColor: "#1976D2",
                    color: "#fff",
                    width: "100%",
                  }}
                >
                  Use It
                </Button>
              </Box>}
          </Box>
        </Popover>
      )}
    </>
  );
}

const mapStateToProps = (state:IState) => {
  return ({
    AiPermission: state?.FeatureReducer?.featurePermissions?.AI,
  })
};

export default connect(mapStateToProps)(AISuggestions);
// export default AISuggestions;
