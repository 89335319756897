import { Box } from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import ChatBot from "../components/ChatBot/ChatBot";
import SideNavbarSSP from "../components/SelfServicePortal/SideBarSSP";
import TopNavbarSSP from "../components/SelfServicePortal/TopNavSSP";
import Loader from "../components/common/loading";
import MySnackbar from "../components/common/snackbars";
import useWindowWidth from "../components/common2/UseWindowWidth";
import SideNavbarForAdmin from "../components/navbar/admin-navbar/side-navbar/SideNavBar";
import TopNavBarForAdmin from "../components/navbar/admin-navbar/top-navbar/TopNavBar";
import SideNavbar from "../components/navbar/sideNavbar/SideNavbar";
import TopNavbar from "../components/navbar/sideNavbar/TopNavbar";
import { IState } from "../redux/reducers/rootReducers";
import CommonStyles from "./../components/common/CommonStyles";
import AppRouter from "./AppRouter";
import MobileAppRouter from "./MobileAppRouter";

const generateLoginScreen = (
  tokenValues:any,
  classes: any,
) => {
  const { isContactLogin,isAdminLogin,isAgentLogin } = tokenValues;
  if (isAgentLogin) {
    return (
      <>
        <MySnackbar />
        <Loader />
        {/* <ChatBot />  */}
        <div className={`sidebar ${classes.BodyRoot}`}>
          {/* <MobileMenu /> */}
          <SideNavbar />
          <TopNavbar />
          <main className="basic-v-gutter">
            <AppRouter />
          </main>
        </div>
      </>
    );
  } else if (isContactLogin) {
    return (
      <>
        {/* <ChatBot />  */}
        <MySnackbar />
        <SideNavbarSSP />
        <div className={`sidebar ${classes.BodyRoot}`}>
          <main className="basic-v-gutter">
            <TopNavbarSSP />
            <AppRouter />
          </main>
        </div>
      </>
    );
  } else if (isAdminLogin) {
    return (
      <>
        <MySnackbar />
        <TopNavBarForAdmin />
        <SideNavbarForAdmin />
        <div className={`sidebar ${classes.BodyRoot}`}>
          <main className="basic-v-gutter">
            <AppRouter />
          </main>
        </div>
      </>
    );
  }
  else {
    return (
      <>
      {/* <h1>WTFFFFFFFFFFFFF</h1> */}
        <AppRouter />
      </>
    );
  }
};

const MainPage: React.FC = ({theme}:any) => {
  const isUserLoggedIn = useSelector((state:IState)=>state?.LoginReducer?.isLoggedIn)
  const classes = CommonStyles();
  const layout =  useMemo(()=>{
    let logInfo: any = localStorage.getItem("loginInfo");
    const tokenValues = JSON.parse(logInfo || "{}");
    console.log("tokenValuestokenValues",isUserLoggedIn,tokenValues)
    return <>
        
        {logInfo ? 
          <>
            {generateLoginScreen(tokenValues,classes)}
          </>
          :
          <>
            <AppRouter />
          </>
        }
    </>
  },[isUserLoggedIn,classes])
  return (<Box className={`${theme ? "dark-mode" : ""}`}>
    {layout}
  </Box>)
};




export function MainPageWrapper() {
  const width = useWindowWidth();
  const [isMobileView, setIsMobileView] = useState(false)
  useEffect(()=>{
    showScreen()
  },[width])
  const showScreen = debounce(() =>{
    setIsMobileView(width <= 768)
  },1000)
  return (
    <>
    <MySnackbar />
      {isMobileView ?
        <>
          <MobileAppRouter />
        </> :
        <MainPage /> 
      }
    </>
  );
}






const mapStateToProps = (state:IState) => {
  return ({
    // isUserLoggedIn : state?.LoginReducer?.isLoggedIn,
    theme : state?.theme?.theme
  })
};

export default connect(mapStateToProps)(MainPage);
//export default MainPage;
