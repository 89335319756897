const configs = {
    'BASE_URL': process.env.REACT_APP_TEST_API_URL,
    'BASE_URL_Ticket': process.env.REACT_APP_TEST_API_URL,
    //PROD-API- https://api.servodesk.com/gateway
    'BASE_URL_FILE_UPLOAD': process.env.REACT_APP_TEST_API_URL,
    'BASE_URL_VERSIONING': "https://simplisysstoragev1.blob.core.windows.net/webasserts/app-version.json",

    'SOURCE': "Phone"
}
export default configs;

