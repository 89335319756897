import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BellIcon, FilterIcon, PlusIconWhite, SearchIcon, SortIcon } from '../../common/svgIcons/chartIcon';
import MobileTicketCard from '../MobileUtilities/MobileTicketCard';
import NavBar from '../MobileUtilities/NavBar';
import TicketActions from '../MobileUtilities/TicketActions';
import SortOption from '../MobileUtilities/SortOption';
import { useDispatch, useSelector } from 'react-redux';
import { storeSortInfo, toggleMobileFilter } from '../../../redux/actions/TicketsManagement/MobileTicketActions';
import TicketList from './Tickets/TicketList';
import MobileFilter from '../MobileUtilities/MobileFilter';
import AddUpdateOption from '../MobileUtilities/AddUpdateOption';
import { getAllTickets } from '../../../redux/actions/TicketsManagement/TicketsManagement';
import { debounce } from 'lodash';
import { useState } from 'react';
import { IState } from '../../../redux/reducers/rootReducers';
import MobileLoader from '../MobileCommon/MobileLoader';
import ContactViewOption from '../MobileUtilities/ContactView';
import MobileTicketFilter from '../MobileUtilities/MobileTicketFilter';
import TicketQuickFilter from '../MobileUtilities/TicketQuickFilter';
function AllMobileTickets() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openSortOption = () =>{
    dispatch(storeSortInfo(true))
  }
  const openFilterOption = () =>{
    dispatch(toggleMobileFilter(true))
}
  return (
    <Box>
        <NavBar 
            pageTitle="All Tickets"
            rightControls={<>
                <Button><BellIcon /></Button>
                <Button onClick={openSortOption}><SortIcon /></Button>
                <Button onClick={openFilterOption}><FilterIcon /></Button>
            </>}
        />
        <TicketQuickFilter />
        <MobileFilter />
        <AddUpdateWithSupport />              

        

        <Box className="p-10p">
            <Box className="searchBtn" onClick={()=>{navigate("/searchResults")}}>
              <SearchIcon />
              <Typography>Search Ticket</Typography>
            </Box>





            <Button className="add-new-ticket-btn" onClick={()=>{navigate("/createTicket")}}>
              <PlusIconWhite />
            </Button>
            

              <TicketList />



              {/* ---------- COMMON MODULES ------------- */}
              <TicketActions />
              <SortOption />
              <MobileTicketFilter />
              <ContactViewOption />

        </Box>
    </Box>
  )
}

const AddUpdateWithSupport = () =>{
  const dispatch = useDispatch();
  const [loader,setLoader] = useState(false);
  const filtersVal = useSelector((state:IState)=>state?.filtersTicketManagementReducer);
  const alldata = useSelector((state:IState)=>state?.TicketsReducer?.allResponses);
  const loadTickets = debounce(() =>{
    setLoader(true);
    console.log("setLoadersetLoader")
    dispatch(getAllTickets(filtersVal,alldata,null,callback));
  },200)
  const callback = debounce(() =>{
    setLoader(false);
  },500)
  return<>
      {loader && <><MobileLoader /></>}
      <AddUpdateOption callback={loadTickets} />
  </>
}

export default AllMobileTickets