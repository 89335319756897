import React, { lazy } from "react";
import { Route } from "react-router-dom";
import About from "../components/postLogin/About";


import DashboardSkeletonLoader from "../components/Dashboard/TicketManagement/TicketDashboardWidgets/views/widgets/DashboardSkeletonLoader";
import Status from "../components/Login/status";
import AllPivotTable from "../components/Reports/AllPivotTable";
import PivotTableReportView from "../components/Reports/PivotTableReportView";
import ViewReport from "../components/Reports/ViewReport";
import ViewService from "../components/SelfServicePortal/ViewServices";
import ViewTicket from "../components/SelfServicePortal/ViewTicket";
import WorkCenterNew from "../components/SelfServicePortal/WorkCenter/WorkCenterNew";
import ContactAIDHolder from "../components/ServicesSSP/AddNew/AddForm";
import FreeTrail1 from "../components/SystemManagement/FreeTrail/FreeTrail1";
import LicenceAgreement from "../components/SystemManagement/FreeTrail/LicenceAgreement";
import TicketManagementSys from "../components/TicketManagementSystem/TicketsManagementSys";
import AddSSOConfig from "../components/adminInterfaces/SSOConfig/AddSSOConfig";
import SSOConfig from "../components/adminInterfaces/SSOConfig/SSOConfig";
import AddTeamsConfig from "../components/adminInterfaces/Teams/AddTeamsConfig";
import TeamsConfig from "../components/adminInterfaces/Teams/TeamsConfig";
import TeamsInstruction from "../components/adminInterfaces/Teams/TeamsInstruction";
import AddNewRole from "../components/userManagement/Role/AddRole-bkp";
import TrialWebForm from "../components/webForms/TrialWebForm";
import WebForms from "../components/webForms/WebForms";
import WebFormList from "../components/webForms/WebFormsList";
import AddEmailSettingsSsp from "../pages/admin-portal/EmailSettingsSsp/AddEmailSettingsSsp";
import EmailSettingsSsp from "../pages/admin-portal/EmailSettingsSsp/EmailSettingsSsp";
import AddEmailTemplatesSsp from "../pages/admin-portal/EmailTemplatesSsp/AddEmailTemplatesSsp";
import EmailTemplateSsp from "../pages/admin-portal/EmailTemplatesSsp/EmailTempleteSsp";
import ProtectedRoute from "./ProtectedRoute";
import KnowledgeManagement from "../components/knowledgeBase/KnowledgeBase";
import CreateArticle from "../components/knowledgeBase/CreateArticle";
import ArticleList from "../components/knowledgeBase/ArticleList";
import ArticleType from "../components/knowledgeBase/ArticleType/ArticleType";
import ArticlesSSP from "../components/knowledgeBase/ArticlesSSP/ArticlesSSP";
import ArticlesListSSP from "../components/knowledgeBase/ArticlesSSP/ArticlesDashboardSSP";
import ViewArticleSSP from "../components/knowledgeBase/ArticlesSSP/ViewArticleSSP";
import ViewArticle from "../components/knowledgeBase/ViewArticle";
import ArticlesAndPermissions from "../components/knowledgeBase/ArticlesAndPermissions/ArticlesAndPermissions";
import UnderConstruction from "../components/UnderConstruction/UnderConstruction";
import AddWebForm from "../components/webForms/AddWebForm";
// import TicketInfoView from "../components/Dashboard/TicketManagement/TicketInfoView";
import AddSurveyTemplate from "../components/Survey/SurveyTemplates/AddSurveyTemplates";
import CreateSurvey from "../components/Survey/CreateSurvey";
import SurveyManagement from "../components/Survey/SurveyManagement";
import SurveyList from "../components/Survey/SurveyList";
import FeedbackView from "../components/Survey/feedbackView";
import SurveyResults from "../components/Survey/SurveyResults/SurveyResults";
import SearchResults from "../components/SelfServicePortal/SearchResults/SearchResults";
import CreateReportCopy from "../components/Reports/CreateReportCopy";
import Ranking from "../components/SystemManagement/Ranking/Ranking";
import ReportsTest from "../components/ReportsTest/ReportsTest";
import CreateCustomReport from "../components/ReportsTest/CreateCustomReport";
import NewTickets from "../components/Dashboard/TicketManagement/TicketDashboardWidgets/NewTickets"
import ContactType from "../components/SystemManagement/cmdb/ContactType";
import SurveyReports from "../components/Survey/SurveyReports/SurveyReports";
import AllNewTickets from "../components/Dashboard/TicketManagement/TicketDashboardWidgets/AllNewTickets";
import AllMobileTickets from "../components/MobileComponents/TicketManagement/AllMobileTickets";
import MailExceptionList from "../components/EmailException/MailExceptionList";
// import AddEmailExceptionList from "../components/EmailException/AddMailExceptionList";
import CreateTicket from "../components/MobileComponents/TicketManagement/Tickets/CreateTicket";
import SearchResultsMobile from "../components/MobileComponents/TicketManagement/SearchResults";
import MobileTicketView from "../components/MobileComponents/TicketManagement/Tickets/MobileTicketView";
import MsNav from "../components/adminInterfaces/MsNav/MsNavList";
import AddMSnav from "../components/adminInterfaces/MsNav/AddMsNavList";
import CreateWallBoard from "../components/adminInterfaces/WallBoard/CreateWallBoard";
import ViewChart from "../components/adminInterfaces/WallBoard/ViewChart";
import { AddEmailExceptionListHome } from "../components/EmailException/AddEmailExceptionListHome";
import DeployTest from "../components/SystemManagement/DeployTest";
import MobileLoader from "../components/MobileComponents/MobileCommon/MobileLoader";
import ErrorLogs from "../pages/admin-portal/ErrorLogs";
import AIResponses from "../components/ChatBot/AiResponses";
import Notification from "../pages/notification/Notification";
import AIManagement from "../components/ChatBot/AI/AIManagement";
import AISync from "../components/ChatBot/AI/AISync";
import ChatHistory from "../components/ChatBot/ChatHistory";
import FeatureControlRoute from "./FeatureControlRoute";
import ViewPricing from "../pages/admin-portal/Pricings/ViewPricing";
import ContactUs from "../pages/contact-us/ContactUs";
const WallBoard = lazy(()=>import("../components/adminInterfaces/WallBoard/WallBoard"));
const SurveyTemplates = lazy(()=>import("../components/Survey/SurveyTemplates/SurveyTemplates"));
const TicketInfoView = lazy(()=>import("../components/Dashboard/TicketManagement/TicketInfoView"));
const SystemManagement = lazy(()=>import( "../components/SystemManagement/SystemManagement"));
const AssetManagement = lazy(()=>import( "../components/SystemManagement/AssetManagement"));
const ContactManagement = lazy(()=>import( "../components/SystemManagement/ContactManagement"));
const UserManagement = lazy(()=>import( "../components/SystemManagement/UserManagement"));
const TicketTypes = lazy(()=>import( "../components/SystemManagement/TicketTypes"));
const Permission = lazy(()=>import( "../components/SystemManagement/Permission/Permission"));
const Priority = lazy(()=>import( "../components/SystemManagement/Priority/Priority"));
const AddPriority = lazy(()=>import( "../components/SystemManagement/Priority/AddPriority"));
const Categories = lazy(()=>import( "../components/SystemManagement/categories/Categories"));
const AddCategories = lazy(()=>import( "../components/SystemManagement/categories/AddCategories"));
const WTP = lazy(()=>import( "../components/SystemManagement/workingTimePlan/WTP"));
const AddWTP = lazy(()=>import( "../components/SystemManagement/workingTimePlan/AddWTP"));
const CreateSLA = lazy(()=>import( "../components/SystemManagement/SLA/CreateSLA"));
const SLA = lazy(()=>import( "../components/SystemManagement/SLA/SLA"));
const TicketStatus = lazy(()=>import( "../components/SystemManagement/TicketStatus/TicketStatus"));
const Groups = lazy(()=>import( "../components/userManagement/Groups/Groups"));
const AddGroups = lazy(()=>import( "../components/userManagement/Groups/AddGroups"));
const Agents = lazy(()=>import( "../components/userManagement/Agents/Agents"));
const AddAgents = lazy(()=>import( "../components/userManagement/Agents/AddAgents"));
const Role = lazy(()=>import( "../components/userManagement/Role/Role"));
const AddRole = lazy(()=>import( "../components/userManagement/Role/AddRole"));
const AgentGroups = lazy(()=>import( "../components/userManagement/AgentGroups/AgentGroups"));
const Pricing = lazy(()=>import( "../components/SystemManagement/pricing"));
const FreeTrial = lazy(()=>import( "../components/SystemManagement/FreeTrial"));
const Success = lazy(()=>import( "../components/SystemManagement/success"));
const Login = lazy(()=>import( "../components/Login/Login"));
const LoginMFA = lazy(()=>import( "../components/Login/MFA"));
const Settings = lazy(()=>import( "./../components/postLogin/Settings"));
const MyLicenses = lazy(()=>import( "../components/postLogin/MyLicenses"));
const Preferences = lazy(()=>import( "../components/postLogin/Preferences"));
const MyProducts = lazy(()=>import( "../components/postLogin/MyProducts"));
const Calender = lazy(()=>import( "../components/calender-management/Calender"));
const ActiveDirectory = lazy(()=>import( "../components/adminInterfaces/ActiveDirectory"));
const AddActiveDirectory = lazy(()=>import( "../components/adminInterfaces/AddActiveDirectory"));
const ContactsDetails = lazy(()=>import( "../components/ContactsManagement/Contacts"));
const AddContact = lazy(()=>import( "../components/ContactsManagement/AddContact"));
const EmailSettings = lazy(()=>import( "../components/EmailSettings/EmailSettings"));
const AddEmailSettings = lazy(()=>import( "../components/EmailSettings/AddEmailSettings"));
const EmailTemplates = lazy(()=>import( "../components/EmailTemplates/EmailTemplates"));
const AllAssetsList = lazy(()=>import( "../components/AssetsManagement/Assets"));
const AddNewAsset = lazy(()=>import( "../components/AssetsManagement/AddAsset"));
const ViewAssetTicket = lazy(()=>import( "./../components/AssetsManagement/ViewTicket"));
const MyOrganisation = lazy(()=>import( "../components/postLogin/CompanyProfile"));
const AssetType = lazy(()=>import( "../components/AssetsManagement/AssetType"));
const AssetStatus = lazy(()=>import( "../components/AssetsManagement/AssetStatus"));
const Location = lazy(()=>import( "../components/SystemManagement/cmdb/Location"));
const Organization = lazy(()=>import( "../components/SystemManagement/cmdb/Organization"));
const Division = lazy(()=>import( "../components/SystemManagement/cmdb/Division"));
const Departments = lazy(()=>import( "../components/SystemManagement/cmdb/Departments"));
const LocationType = lazy(()=>import( "../components/SystemManagement/cmdb/LocationType"));
const AddEmailTemplates = lazy(()=>import( "../components/EmailTemplates/AddEmailTemplates"));
const CompanySettings = lazy(()=>import( "../components/postLogin/CompanySettings"));
const OrganizationType = lazy(()=>import( "../components/SystemManagement/cmdb/OrganizationType"));
const LatestTicket = lazy(()=>import( "../pages/new-ticket/LatestTicket/Home"));
const AllHistory = lazy(()=>import( "../pages/history/AllHistory"));
const AdditionalFields = lazy(()=>import( "../components/AdditionalFields/AdditionalFields"));
const AddAdditionalFields = lazy(()=>import( "../components/AdditionalFields/AddAdditionalFields"));
const AddAssetType = lazy(()=>import( "../components/AssetsManagement/AddAssetType"));
const Report = lazy(()=>import( "../components/Reports/TestReport"));
const PowerBI = lazy(()=>import( "../components/Reports/powerBI"));
const AllReports = lazy(()=>import( "../components/Reports/AllReports"));
const CSVReport = lazy(()=>import( "../components/Reports/ReportCSVHolder"));
const PowerBIList = lazy(()=>import( "../components/adminInterfaces/PowerBIModules/PowerBI"));
const AddPowerBI = lazy(()=>import( "../components/adminInterfaces/PowerBIModules/AddPowerBI"));
const CreateReport = lazy(()=>import( "../components/Reports/CreateReport"));
const ReportManagement = lazy(()=>import( "../components/Reports/ReportManagement"));
const LandingPageSSP = lazy(()=>import( "../components/SelfServicePortal/LandingPage"));
const NewTicketSSP = lazy(()=>import( "../components/SelfServicePortal/NewTicket"));
const AddOrganization = lazy(()=>import( "../components/SystemManagement/cmdb/AddOrganization"));
const ServicesSSPList = lazy(()=>import( "../components/ServicesSSP/ServicesSSPList"));
const AidServices = lazy(()=>import( "../components/SelfServicePortal/AidServices"));
const AssetsListing = lazy(()=>import( "../components/SelfServicePortal/Assets"));
const Admin = lazy(()=>import( "../pages/admin-portal/Admin"));
const ResetPasswordLogin = lazy(()=>import( "../components/Login/resetPassword"));
const ContactResetPassword = lazy(()=>import( "../components/Login/ContactResetPassword"));
const ForgotPassword = lazy(()=>import( "../components/Login/ForgotPassword"));
const MyTaskApproval = lazy(()=>import( "../components/SelfServicePortal/MyTaskApproval"));
const MyApproval = lazy(()=>import( "../components/SelfServicePortal/MyApproval"));
const EditPricingRouting = lazy(()=>import( "../pages/admin-portal/EditPricingRouting"));
const ForgotUsername = lazy(()=>import( "../components/Login/ForgotUsername"));
const AdminLogin = lazy(()=>import( "../components/Login/AdminLogin"));
const SourceView = lazy(()=>import( "../services/systemManagement/source/SourceView"));
const MyProfileSSP = lazy(()=>import( "../components/ServicesSSP/MyProfileSSP"));
const WhatsApp = lazy(()=>import( "../components/adminInterfaces/WhatsApp/WhatsApp"));
const AddWhatsApp = lazy(()=>import( "../components/adminInterfaces/WhatsApp/AddWhatsApp"));
const AddWorkflow = lazy(()=>import( "../components/workflowConfig/workflow"));
const WorkflowList = lazy(()=>import( "../components/workflowConfig/workflowList"))
const AddLocation = lazy(()=>import( "../components/SystemManagement/cmdb/AddLocation"));
const Intune=lazy(()=>import("../components/adminInterfaces/Intune/Intune"));
const AddIntune=lazy(()=>import("../components/adminInterfaces/Intune/AddIntune"));
const ViewLocation=lazy(()=>import("../components/SystemManagement/cmdb/ViewLocation"));

/*======LAZY LOADING CHANGES=====*/
//import Home from "../pages/home/Home";
//import TicketDashboardHolder from "../components/Dashboard/TicketManagement/TicketDashboardHolder";
const Home = lazy(()=>import("../pages/home/Home"))
//const TicketDashboardHolder = lazy(()=>import("../components/Dashboard/TicketManagement/TicketDashboardHolder"))
export function SSPAuth() {
  return (
    <React.Fragment>
      <Route path="/myTaskApproval" element={<MyTaskApproval />} />
      <Route path="/myApproval" element={<MyApproval />} />
      <Route path="/workCenter" element={<WorkCenterNew />} />
      <Route path="/UnderConstruction" element={<UnderConstruction />} />
      {/* <Route path="/ServicesDetails" element={<AidServiceDetails />} /> */}
      <Route path="/AidServices/:id" element={<AidServices />} />
      <Route path="/AidServices" element={<AidServices />} />
      <Route path="/ViewService" element={<ViewService />} />
      <Route path="/ViewTicket/:id" element={<ViewTicket />} />
      <Route path="/MyProfileSSP" element={<MyProfileSSP />} />
      <Route path="/addNew" element={<NewTicketSSP />} />
      <Route path="/myAssets" element={<AssetsListing />} />
      <Route path="/ViewAsset/:id" element={<ViewAssetTicket />} />
      <Route path="/ArticlesSSP" element={<ArticlesSSP />} />
      <Route path="/Articles" element={<ArticlesListSSP />} />
      <Route path="/ArticlesList/:id" element={<ViewArticleSSP />} />
      <Route path="/SearchResults/:value" element={<SearchResults />} />
      <Route path="/SearchResults/" element={<SearchResults />} />
      <Route path="/*" element={<LandingPageSSP />} />
    </React.Fragment>
  );
}

export function PrivateAuth() {
  return (
    <React.Fragment>
      <Route path="/pricing" element={<ViewPricing />} />
      <Route path="/Feedback/:id" element={<FeedbackView />}/>
      <Route path="/ViewChart/:code" element={<ViewChart />}/>
      <Route path="/freeTrial/:id" element={<FreeTrail1 />} />
      <Route path="/LicenceAgreement" element={<LicenceAgreement/>} />
      <Route path="/success" element={<Success />} />
      <Route path="/DeployTest" element={<DeployTest />} />
      <Route path="/success/:val" element={<Success />} />
      <Route path="/LoginMFA/:val" element={<LoginMFA />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/ContactResetPassword/:val"
        element={<ContactResetPassword />}
      />
      <Route path="/resetPassword/:val" element={<ResetPasswordLogin />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/forgotUsername" element={<ForgotUsername />} />
      <Route path="/admin-portal" element={<AdminLogin />} />
      <Route path="/Status" element={<Status />} />
      <Route path="/*" element={<Login />} />
      {/*
        <Route path="/TicketManagementSys" element={<AllMobileTickets />} />
        <Route path="/createTicket" element={<CreateTicket />} />
        <Route path="/createTicket/:ticketId" element={<CreateTicket />} />
        <Route path="/ticketInfoView/:ticketId" element={<TicketInfoView />}  />
        <Route path="/searchResults" element={<SearchResultsMobile />} /> 
      */}
      {/* <Route path="/*" element={<AllMobileTickets />} /> */}
    </React.Fragment>
  );
}
export function AdminPortalAuth() {
  return (
    <React.Fragment>
      <Route path="/freeTrial/:id" element={<FreeTrail1/>} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/edit-pricing" element={<EditPricingRouting />} />
      <Route path="/EmailSettingsSsp" element={<EmailSettingsSsp/>}/>
      <Route path="/AddEmailSettingsSsp" element={<AddEmailSettingsSsp/>}/>
      <Route path="/AddEmailSettingsSsp/:id" element={<AddEmailSettingsSsp/>}/>
      <Route path="/EmailTempleteSsp" element={<EmailTemplateSsp/>}/>
      <Route path="/AddEmailTempleteSsp" element={<AddEmailTemplatesSsp/>}/>
      <Route path="/AddEmailTempleteSsp/:id" element={<AddEmailTemplatesSsp/>}/>
      <Route path="/admin-portal/ErrorLog" element={<ErrorLogs/>}/>

      <Route path="/*" element={<Admin />} />
      
      
    </React.Fragment>
  );
}
export function MobileLoginLoader() {
  return (
    <React.Fragment>
      <Route path="/*" element={<MobileLoader />} />
    </React.Fragment>
  )
}
export function PostLoginLoader() {
  return (
    <React.Fragment>
      <Route path="/*" element={<DashboardSkeletonLoader />} />
    </React.Fragment>
  )
}
export function PostLoginAdminAuth1() {
  return (
    <React.Fragment>
        <Route path="/TicketManagementSys" element={<ProtectedRoute path="/TicketManagementSys" component={<TicketManagementSys />} />} />
        <Route path="/*" element={<Home />} />
    </React.Fragment>)
}
export function PostLoginAdminAuth() {
  return (
    <React.Fragment>
      {/* ==== SYSTEM MANAGEMENT ==== */}
      <Route path="/CreateCustomReport" element={<ProtectedRoute path="/CreateCustomReport" component={<CreateCustomReport />} />} />
      <Route path="/ReportsTest" element={<ProtectedRoute path="/ReportsTest" component={<ReportsTest />} />} />
      <Route path="/adminPanel" element={<ProtectedRoute path="/adminPanel" component={<SystemManagement />} />} />
      <Route path="/Ticket-Types" element={<ProtectedRoute path="/Ticket-Types" component={<TicketTypes />} />} />
      <Route path="/Permission" element={<ProtectedRoute path="/Permission" component={<Permission />} />} />
      <Route path="/Category" element={<ProtectedRoute path="/Category" component={<Categories />} />} />
      <Route path="/category/add-categories" element={<ProtectedRoute path="/category/add-categories" component={<AddCategories />} />} />
      <Route path="/category/:id" element={<ProtectedRoute path="/category/:id" component={<AddCategories />} />} />
      <Route path="/Priority" element={<ProtectedRoute path="/Priority" component={<Priority />} />} />
      <Route path="/priority/add-priority" element={<ProtectedRoute path="/priority/add-priority" component={<AddPriority />} />} />
      <Route path="/priority/:id" element={<ProtectedRoute path="/priority/:id" component={<AddPriority />} />} />
      <Route path="/Working-Time-Plan" element={<ProtectedRoute path="/Working-Time-Plan" component={<WTP />} />} />
      <Route path="/Working-Time-Plan/create-wtp" element={<ProtectedRoute path="/Working-Time-Plan/create-wtp" component={<AddWTP />} />} />
      <Route path="/Working-Time-Plan/:id" element={<ProtectedRoute path="/Working-Time-Plan/:id" component={<AddWTP />} />} />
      <Route path="/SLA" element={<ProtectedRoute path="/SLA" component={<SLA />} />} />
      <Route path="/SLA/create-sla" element={<ProtectedRoute path="/SLA/create-sla" component={<CreateSLA />} />} />
      <Route path="/SLA/:id" element={<ProtectedRoute path="/SLA/:id" component={<CreateSLA />} />} />
      <Route path="/Ticket-Status" element={<ProtectedRoute path="/Ticket-Status" component={<TicketStatus />} />} />
      <Route path="/MailExceptionList" element={<ProtectedRoute path="/MailExceptionList" component={<MailExceptionList />} />} />
      <Route path="/Add-MailExceptionList/:id" element={<ProtectedRoute path="/Add-MailExceptionList/:id" component={<AddEmailExceptionListHome />} />} />
      <Route path="/Add-MailExceptionList" element={<ProtectedRoute path="/Add-MailExceptionList" component={<AddEmailExceptionListHome />} />} />
      <Route path="/EmailTemplates" element={<ProtectedRoute path="/EmailTemplates" component={<EmailTemplates />} />} />
      <Route path="/Add-EmailTemplates/:id" element={<ProtectedRoute path="/Add-EmailTemplates/:id" component={<AddEmailTemplates />} />} />
      <Route path="/Add-EmailTemplates" element={<ProtectedRoute path="/Add-EmailTemplates" component={<AddEmailTemplates />} />} />
      <Route path="/Ranking" element={<ProtectedRoute path="/Ranking" component={<Ranking />} />} />
      
      {/* ==== WORKFLOW ==== */}
      <Route path="/workflow-list" element={<FeatureControlRoute featureName="Workflows" path={"/workflow-list"} component={<WorkflowList />} />} />
      <Route path="/workflow/:id" element={<FeatureControlRoute featureName="Workflows" path={"/workflow/:id"} component={<AddWorkflow />} />} />
      <Route path="/workflow" element={<FeatureControlRoute featureName="Workflows" path={"/workflow"} component={<AddWorkflow />} />} />

      {/* ==== SOURCES ==== */}
      <Route path="/source" element={<ProtectedRoute path="/source" component={<SourceView/>} />} />
      <Route path="/WhatsApp" element={<ProtectedRoute path="/WhatsApp" component={<WhatsApp />} />} />
      <Route path="/AddWhatsApp" element={<ProtectedRoute path="/AddWhatsApp" component={<AddWhatsApp />} />} />
      <Route path="/AddWhatsApp/:id" element={<ProtectedRoute path="/AddWhatsApp/:id" component={<AddWhatsApp />} />} />
      <Route path="/TeamsInstruction" element={<ProtectedRoute path="/TeamsInstruction" component={<TeamsInstruction />} />} />

      {/* ==== INTERFACES ==== */}
      <Route path="/ActiveDirectory" element={<ProtectedRoute path="/ActiveDirectory" component={<ActiveDirectory />} />} />
      <Route path="/AddActiveDirectory" element={<ProtectedRoute path="/AddActiveDirectory" component={<AddActiveDirectory />} />} />
      <Route path="/AddActiveDirectory/:id" element={<ProtectedRoute path="/AddActiveDirectory/:id" component={<AddActiveDirectory />} />} />
      <Route path="/EmailSettings" element={<ProtectedRoute path="/EmailSettings" component={<EmailSettings />} />} />
      <Route path="/Add-EmailSettings/:id" element={<ProtectedRoute path="/Add-EmailSettings/:id" component={<AddEmailSettings />} />} />
      <Route path="/Add-EmailSettings" element={<ProtectedRoute path="/Add-EmailSettings" component={<AddEmailSettings />} />} />
      <Route path="/PowerBISettings" element={<ProtectedRoute path="/PowerBISettings" component={<PowerBIList />} />} />
      <Route path="/AddPowerBI" element={<ProtectedRoute path="/AddPowerBI" component={<AddPowerBI />} />} />
      <Route path="/AddPowerBI/:id" element={<ProtectedRoute path="/AddPowerBI/:id" component={<AddPowerBI />} />} />
      <Route path="/Intune" element={<ProtectedRoute path="/Intune" component={<Intune />} />} />
      <Route path="/AddIntune" element={<ProtectedRoute path="/AddIntune" component={<AddIntune />} />} />
      <Route path="/AddIntune/:id" element={<ProtectedRoute path="/AddIntune/:id" component={<AddIntune />} />} />
      <Route path="/SSOConfig" element={<ProtectedRoute path="/SSOConfig" component={<SSOConfig/>}/>}/>
      <Route path="/AddSSOConfig" element={<ProtectedRoute path="/AddSSOConfig" component={<AddSSOConfig/>} />}/>
      <Route path="/AddSSOConfig/:id" element={<ProtectedRoute path="/AddSSOConfig" component={<AddSSOConfig/>} />}/>
      <Route path="/TeamsConfig" element={<ProtectedRoute path="/TeamsConfig" component={<TeamsConfig/>}/>}/>
      <Route path="/AddTeamsConfig" element={<ProtectedRoute path="/AddTeamsConfig" component={<AddTeamsConfig/>} />}/>
      <Route path="/MSnav" element={<ProtectedRoute path="/MSnav" component={<MsNav />} />} />
      <Route path="/AddMSnav" element={<ProtectedRoute path="/AddMSnav" component={<AddMSnav />} />} />
      {/* ==== SSP CONFIGURATION ==== */}
      <Route path="/ServicesSSPList" element={<ProtectedRoute path="/ServicesSSPList" component={<ServicesSSPList />} />} />
      <Route path="/contact-us" element={<ProtectedRoute path="/contact-us" component={<ContactUs />} />} />
      <Route path="/AddServicesSSP" element={<ProtectedRoute path="/AddServicesSSP" component={<ContactAIDHolder />} />} />
      <Route path="/ServicesSSPList/:id" element={<ProtectedRoute path="/ServicesSSPList/:id" component={<ContactAIDHolder />} />} />
      <Route path="/AddContactAid" element={<ProtectedRoute path="/AddContactAid" component={<ContactAIDHolder />} />} />
      <Route path="/AddContactAid/:id" element={<ProtectedRoute path="/AddContactAid" component={<ContactAIDHolder />} />} />



      {/* ==== USER MANAGEMENT ==== */}
      <Route path="/UserManagement" element={<ProtectedRoute path="/UserManagement" component={<UserManagement />} />} />
      <Route path="/Groups" element={<ProtectedRoute path="/Groups" component={<Groups />} />} />
      <Route path="/Groups/add-groups" element={<ProtectedRoute path="/Groups/add-groups" component={<AddGroups />} />} />
      <Route path="/Groups/:id" element={<ProtectedRoute path="/Groups/:id" component={<AddGroups />} />} />
      <Route path="/Agents" element={<ProtectedRoute path="/Agents" component={<Agents />} />} />
      <Route path="/Agents/add-agents" element={<ProtectedRoute path="/Agents/add-agents" component={<AddAgents />} />} />
      <Route path="/Agents/:id" element={<ProtectedRoute path="/Agents/:id" component={<AddAgents />} />} />
      
      <Route path="/Roles" element={<ProtectedRoute path="/Roles" component={<Role />} />} />
      <Route path="/Roles/add-roles" element={<ProtectedRoute path="/Roles/add-roles" component={<AddRole />} />} />
      <Route path="/Roles/:id" element={<ProtectedRoute path="/Roles/:id" component={<AddNewRole />} />} />
      <Route path="/AddRole" element={<AddNewRole />} />
      
      <Route path="/Agent-Groups" element={<ProtectedRoute path="/Agent-Groups" component={<AgentGroups />} />} />
      {/* ==== UNDER CONSTRUCTION PAGE ==== */}
      <Route path="/UnderConstruction" element={<ProtectedRoute path="/UnderConstruction" component={<UnderConstruction />} />} />

      {/* ==== LICENSE AND SETTINGS ==== */}
      <Route path="/Settings" element={<ProtectedRoute path="/Settings" component={<Settings />} />} />
      <Route path="/organisationProfile" element={<ProtectedRoute path="/organisationProfile" component={<MyOrganisation />} />} />
      <Route path="/organisationSettings" element={<ProtectedRoute path="/organisationSettings" component={<CompanySettings />} />} />
      <Route path="/MyProducts" element={<ProtectedRoute path="/MyProducts" component={<MyProducts />} />} />
      <Route path="/about" element={<ProtectedRoute path="/about" component={<About />} />} />

      {/* ==== PROFILE & PREFERENCES ==== */}
      <Route path="/Preferences" element={<ProtectedRoute path="/Preferences" component={<Preferences />} />} />
      <Route path="/MyProfile" element={<ProtectedRoute path="/MyProfile" component={<MyLicenses />} />} />

      {/* ==== CONTACT MANAGEMENT ==== */}
      <Route path="/ContactManagement" element={<ProtectedRoute path="/ContactManagement" component={<ContactManagement />} />} />
      <Route path="/contact" element={<ProtectedRoute path="/contact" component={<ContactsDetails />} />} />
      <Route path="/add-contact/:id" element={<ProtectedRoute path="/add-contact/:id" component={<AddContact />} />} />
      <Route path="/add-contact" element={<ProtectedRoute path="/add-contact" component={<AddContact />} />} />
      <Route path="/ContactType" element={<ProtectedRoute path="/ContactType" component={<ContactType />} />} />
      <Route path="/Organization" element={<ProtectedRoute path="/Organization" component={<Organization />} />} />
      <Route path="/Location" element={<ProtectedRoute path="/Location" component={<Location />} />} />
      <Route path="/Location/Add-Location" element={<ProtectedRoute path="/Location/Add-Location" component={<AddLocation />} />} />
      <Route path="/Add-Location/:id" element={<ProtectedRoute path="/Add-Location/:id" component={<AddLocation />} />} />
      <Route path="/ViewLocation/:id" element={<ProtectedRoute path="/ViewLocation/:id" component={<ViewLocation />} />} />
      <Route path="/LocationType" element={<ProtectedRoute path="/LocationType" component={<LocationType />} />} />
      <Route path="/Organization/Add-Organization" element={<ProtectedRoute path="/Organization/Add-Organization" component={<AddOrganization />} />} />
      <Route path="/Add-Organization/:id" element={<ProtectedRoute path="/Add-Organization/:id" component={<AddOrganization />} />} />
      <Route path="/Division" element={<ProtectedRoute path="/Division" component={<Division />} />} />
      <Route path="/Department" element={<ProtectedRoute path="/Department" component={<Departments />} />} />
      <Route path="/OrganizationType" element={<ProtectedRoute path="/OrganizationType" component={<OrganizationType />} />} />

      {/* ==== CMDB ==== */}
      <Route path="/AssetManagement" element={<ProtectedRoute path="/AssetManagement" component={<AssetManagement />} />} />
      <Route path="/CMDB" element={<ProtectedRoute path="/CMDB" component={<AllAssetsList />} />} />
      <Route path="/AddAsset" element={<ProtectedRoute path="/AddAsset" component={<AddNewAsset />} />} />
      <Route path="/AddAsset/:id" element={<ProtectedRoute path="/AddAsset/:id" component={<AddNewAsset />} />} />
      <Route path="/ViewAsset/:id" element={<ProtectedRoute path="/ViewAsset/:id" component={<ViewAssetTicket />} />} />
      <Route path="/Asset-Type" element={<ProtectedRoute path="/Asset-Type" component={<AssetType />} />} />
      <Route path="/Add-AssetType" element={<ProtectedRoute path="/Add-AssetType" component={<AddAssetType />} />} />
      <Route path="/Add-AssetType/:id" element={<ProtectedRoute path="/Add-AssetType/:id" component={<AddAssetType />} />} />
      <Route path="/AssetStatus" element={<ProtectedRoute path="/AssetStatus" component={<AssetStatus />} />} />
      <Route path="/AdditionalFields" element={<ProtectedRoute path="/AdditionalFields" component={<AdditionalFields />} />} />
      <Route path="/Add-AdditionalFields" element={<ProtectedRoute path="/Add-AdditionalFields" component={<AddAdditionalFields />} />} />
      <Route path="/Add-AdditionalFields/:id" element={<ProtectedRoute path="/Add-AdditionalFields/:id" component={<AddAdditionalFields />} />} />

      {/* ==== REPORTS MANAGEMENT ==== */}
      <Route path="/ReportsManagement" element={<ProtectedRoute path="/ReportsManagement" component={<ReportManagement />} />} />
      <Route path="/All-Reports" element={<ProtectedRoute path="/All-Reports" component={<AllReports />} />} />
      <Route path="/CreateReport" element={<ProtectedRoute path="/CreateReport" component={<CreateReport />} />} />
      <Route path="/CreateReport/:id" element={<ProtectedRoute path="/CreateReport/:id" component={<CreateReport />} />} />
      <Route path="/CreateReport/view/:id" element={<ProtectedRoute path="/CreateReport/view/:id" component={<CreateReportCopy />} />} />
      <Route path="/All-Pivot-Table" element={<ProtectedRoute path="/All-Pivot-Table" component={<AllPivotTable />} />} />
      <Route path="/Report" element={<ProtectedRoute path="/Report" component={<Report />} />} />
      <Route path="/CSVReport" element={<ProtectedRoute path="/CSVReport" component={<CSVReport />} />} />
      <Route path="/pivot-table" element={<ProtectedRoute path="/pivot-table" component={<PivotTableReportView />} />} />
      <Route path="/pivot-table/:id" element={<ProtectedRoute path="/pivot-table/:id" component={<PivotTableReportView />} />} />
      <Route path="/PowerBI" element={<ProtectedRoute path="/PowerBI" component={<PowerBI />} />} />
      <Route path="/ViewReport/:id" element={<ProtectedRoute path="/ViewReport/:id" component={<ViewReport />} />} />

      {/* ==== WALLBOARD ==== */}
      <Route path="/WallBoard" element={<FeatureControlRoute featureName="WallBoard" path={"/WallBoard"} component={<WallBoard />} />} />
      <Route path="/CreateWallBoard" element={<FeatureControlRoute featureName="WallBoard" path="/CreateWallBoard" component={<CreateWallBoard />} />} />
      <Route path="/CreateWallBoard/:id" element={<FeatureControlRoute featureName="WallBoard" path="/CreateWallBoard/:id" component={<CreateWallBoard />} />} />
      <Route path="/ViewChart/:id" element={<FeatureControlRoute featureName="WallBoard" path="/ViewChart/:id" component={<ViewChart />} />} />

      {/* ==== TICKETS MANAGEMENT ==== */}
      <Route path="/NewTickets" element={<ProtectedRoute path="/NewTickets" component={<NewTickets />} />} />
      <Route path="/TicketManagement" element={<ProtectedRoute path="/TicketManagement" component={<TicketManagementSys />} />} />
      <Route path="/TicketManagementSys" element={<ProtectedRoute path="/TicketManagementSys" component={<TicketManagementSys />}/>} />
      <Route path="/home/ticket/new-ticket" element={<ProtectedRoute path="/home/ticket/new-ticket" component={<LatestTicket />} />} />
      <Route path="/home/ticket/:ticketId" element={<ProtectedRoute path="/home/ticket/:ticketId" component={<LatestTicket />} />} />
      <Route path="/TicketInfoView/:ticketId" element={<ProtectedRoute path="/TicketInfoView/:ticketId" component={<TicketInfoView />} />} />
      <Route path="/calender" element={<ProtectedRoute path="/calender" component={<Calender />} />} />
      <Route path="/history/:ticketId" element={<ProtectedRoute path="/history/:ticketId" component={<AllHistory />} />} />
      <Route path="/notifications" element={<ProtectedRoute path="/notifications" component={<Notification />} />} />
      <Route path="/AllNewTickets" element={<ProtectedRoute path="/AllNewTickets" component={<AllNewTickets />} />} />
      
      {/* ==== WEBFORMS PAGES ==== */}
      <Route path="/trial-webForms" element={<FeatureControlRoute featureName="Webforms" path={"/trial-webForms"} component={<TrialWebForm />} />} />
      <Route path="/webForms" element={<FeatureControlRoute featureName="Webforms" path={"/webForms"} component={<AddWebForm />} />} />
      <Route path="/webForms/:id" element={<FeatureControlRoute featureName="Webforms" path={"/webForms"} component={<AddWebForm />} />} />
      <Route path="/webFormList" element={<FeatureControlRoute featureName="Webforms" path={"/webFormList"} component={<WebFormList />} />} />
      
      {/* ==== KNOWLEDGEBASE PAGES ==== */}
      <Route path="/createArticle" element={<ProtectedRoute path="/createArticle" component={<CreateArticle />} />} />
      <Route path="/KnowledgeBase" element={<ProtectedRoute path="/KnowledgeBase" component={<KnowledgeManagement />} />} />
      <Route path="/ArticlesList" element={<ProtectedRoute path="/ArticlesList" component={<ArticleList />} />} />
      <Route path="/ArticlesList/:id" element={<ProtectedRoute path="/ArticlesList/:id" component={<CreateArticle />} />} />
      <Route path="/ArticleType" element={<ProtectedRoute path="/ArticleType" component={<ArticleType />} />} />
      <Route path="/ViewArticle/:id" element={<ProtectedRoute path="/ViewArticle/:id" component={<ViewArticle />} />} />
      <Route path="/ArticlesAndPermissions" element={<ProtectedRoute path="/ArticlesAndPermissions" component={<ArticlesAndPermissions />} />} />

      {/* ==== Survey PAGES ==== */}
      <Route path="/surveyManagement" element={<FeatureControlRoute featureName="Surveys" path={"/surveyManagement"} component={<SurveyManagement />} />} />
      <Route path="/createSurvey" element={<FeatureControlRoute featureName="Surveys" path={"/createSurvey"} component={<CreateSurvey />} />} />
      <Route path="/SurveyList" element={<FeatureControlRoute featureName="Surveys" path={"/SurveyList"} component={<SurveyList />} />} />
      <Route path="/SurveyList/:id" element={<FeatureControlRoute featureName="Surveys" path={"/SurveyList/:id"} component={<CreateSurvey />} />} />
      <Route path="/SurveyResults" element={<FeatureControlRoute featureName="Surveys" path={"/SurveyResults"} component={<SurveyResults />} />} />
      <Route path="/SurveyReports" element={<FeatureControlRoute featureName="Surveys" path={"/SurveyReports"} component={<SurveyReports />} />} />
      <Route path="/SurveyTemplates" element={<FeatureControlRoute featureName="Surveys" path={"/SurveyTemplates"} component={<SurveyTemplates />} />} />
      <Route path="/AddSurveyTemplate" element={<FeatureControlRoute featureName="Surveys" path={"/AddSurveyTemplate"} component={<AddSurveyTemplate />} />} />
      <Route path="/AddSurveyTemplate/:id" element={<FeatureControlRoute featureName="Surveys" path={"/AddSurveyTemplate/:id"} component={<AddSurveyTemplate />} />} />

      
      {/* ==== AI PAGES ==== */}
      <Route path="/AI-Search" element={<FeatureControlRoute featureName="AI" path={"/AI-Search"} component={<AIResponses />} />} />
      <Route path="/AIManagement" element={<FeatureControlRoute featureName="AI" path={"/AIManagement"} component={<AIManagement />} />} />
      <Route path="/AI-Sync" element={<FeatureControlRoute featureName="AI" path={"/AI-Sync"} component={<AISync />} />} />
      <Route path="/ChatHistory" element={<FeatureControlRoute featureName="AI" path={"/ChatHistory"} component={<ChatHistory />} />} />
      {/* <Route path="/AI-Search" element={<AIResponses />} />
      <Route path="/AIManagement" element={<AIManagement />} />
      <Route path="/AI-Sync" element={<AISync />} />
      <Route path="/ChatHistory" element={<ChatHistory />} /> */}


      {/* ==== LANDING PAGES ==== */}
      <Route path="/home" element={<Home />} />
      <Route path="/*" element={<Home />} />
    </React.Fragment>
  );
}

export function MobileLoggedInAuth() {
  return (
    <React.Fragment>
      {/* ==== PROFILE & PREFERENCES ==== */}
      <Route path="/MyProfile" element={<ProtectedRoute path="/MyProfile" component={<MyLicenses />} />} />

      {/* ==== TICKETS MANAGEMENT ==== */}      
        <Route path="/TicketManagementSys" element={<AllMobileTickets />} />
        <Route path="/createTicket" element={<CreateTicket />} />
        <Route path="/createTicket/:ticketId" element={<CreateTicket />} />
        <Route path="/ticketInfoView/:ticketId" element={<MobileTicketView />}  />
        <Route path="/searchResults" element={<SearchResultsMobile />} /> 
      {/* ==== LANDING PAGES ==== */}
      <Route path="/*" element={<ProtectedRoute path="/TicketManagementSys" component={<AllMobileTickets />}/>} />
    </React.Fragment>
  );
}
